// Imports => Vendor
import axios from 'axios';

// Imports => Dependencies
import {
	makeObservable,
	observable,
	computed,
	action,
	runInAction,
} from 'mobx';

// Imports => DayJS
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// Imports => Config
import config from '@config';

// Imports => Constants
import { KEYS, PERMISSIONS } from '@constants';

// Imports => Utilities
import {
	AcSanitize,
	AcAutoLoad,
	AcAutoSave,
	AcGetState,
	AcSaveState,
	AcRemoveState,
	AcClearState,
	AcIsSet,
	AcIsUndefined,
	AcFormatErrorMessage,
	AcFormatErrorCode,
	AcGetAccessToken,
} from '@utils';

let app = {};
let _authTimer = null;

export class AuthStore {
	constructor(store) {
		makeObservable(this);

		AcAutoLoad(this, KEYS.IMPERSONATED);
		AcAutoLoad(this, KEYS.IMPERSONATED_ACCESS_TOKEN);
		AcAutoLoad(this, KEYS.IMPERSONATED_REFRESH_TOKEN);
		AcAutoLoad(this, KEYS.ACCOUNT);
		AcAutoLoad(this, KEYS.ACCESS_TOKEN);
		AcAutoLoad(this, KEYS.EXPIRES_IN);
		AcAutoLoad(this, KEYS.EXPIRES_AT);
		AcAutoLoad(this, KEYS.LAST_ACTIVITY);
		AcAutoLoad(this, KEYS.REFRESH_TOKEN);
		AcAutoSave(this);

		dayjs.extend(localizedFormat);
		dayjs.extend(relativeTime);
		dayjs.extend(advancedFormat);
		dayjs.locale(config.locale);

		app.store = store;

		window.addEventListener('unAuthenticate', this.unAuthenticate);
	}

	@observable impersonated_access_token = null;

	@observable impersonated_refresh_token = null;

	@observable impersonated = null;

	@observable redirect_url = null;

	@observable access_token = null;

	@observable expires_in = null;

	@observable expires_at = null;

	@observable last_activity = null;

	@observable account = null;

	@observable loaded = false;

	@observable error = null;

	@observable
	loading = {
		status: false,
		message: undefined,
	};

	@computed
	get is_loading() {
		return this.loading.status;
	}

	@computed
	get is_impersonating() {
		return AcIsSet(this.impersonated);
	}

	@computed
	get current_impersonated() {
		return this.impersonated;
	}

	@computed
	get current_error() {
		return this.error;
	}

	@computed
	get current_access_token() {
		const token = AcGetAccessToken();
		if (AcIsSet(token)) return token;
		if (this.is_impersonating) return this.impersonated.access_token;
		return this.access_token;
	}

	@computed
	get current_expires_in() {
		return this.expires_in;
	}

	@computed
	get current_expires_at() {
		return this.expires_at;
	}

	@computed
	get current_last_activity() {
		const sharedLastActivity = localStorage.getItem(KEYS.LAST_ACTIVITY);
		if (sharedLastActivity) return parseInt(sharedLastActivity);
		return this.last_activity;
	}

	@computed
	get current_account() {
		if (this.is_impersonating) return this.impersonated;
		return this.account;
	}

	@computed
	get current_account_id() {
		if (this.is_impersonating)
			return (this.impersonated && this.impersonated.user_id) || null;
		return (this.account && this.account.user_id) || null;
	}

	@computed
	get current_username() {
		if (this.is_impersonating)
			return (this.impersonated && this.impersonated.user_name) || null;
		return (this.account && this.account.user_name) || null;
	}

	@computed
	get current_role() {
		if (this.is_impersonating)
			return (this.impersonated && this.impersonated.user_role) || null;
		return (this.account && this.account.user_role) || null;
	}

	@computed
	get current_roles() {
		if (this.is_impersonating)
			return (this.impersonated && this.impersonated.roles) || [];
		return (this.account && this.account.roles) || [];
	}

	@computed
	get current_permissions() {
		if (this.is_impersonating)
			return (this.impersonated && this.impersonated.permissions) || {};
		return (this.account && this.account.permissions) || {};
	}

	@computed
	get current_name() {
		if (this.is_impersonating)
			return (this.impersonated && this.impersonated.name) || null;
		return (this.account && this.account.name) || null;
	}

	@computed
	get is_authorized() {
		let authorized = false;

		const account = this.current_account;
		const access_token = this.current_access_token;
		const expires_at = this.current_expires_at;
		const now = dayjs();
		let expired = true;
		let expires_in = 0;

		if (AcIsSet(expires_at)) {
			expired = expires_at && dayjs(expires_at).isBefore(now);
			expires_in = expires_at && dayjs(expires_at).fromNow();
		}

		console.group('[store] Auth => Is Authorized');
		console.log('Impersonating: ', this.is_impersonating);
		console.log('Roles: ', this.current_roles.slice());
		console.log('Now:', dayjs(now).format('LLLL'));
		console.log('Expires_at: ', dayjs(expires_at).format('LLLL:ss'));
		console.log('Expires in: ', expires_in);
		console.log('Is Expired: ', expired);

		authorized =
			AcIsSet(account) && AcIsSet(access_token) && !expired ? true : false;

		console.log('Authorized: ', authorized);
		console.groupEnd();

		return authorized === true;
	}

	@action
	setLoading = (state, message) => {
		this.loading = {
			status: state || false,
			message: message || false,
		};
	};

	@action
	setLastActivity = (timestamp) => {
		this.set(KEYS.LAST_ACTIVITY, timestamp);
	};

	@action
	clearAuthentication = () => {
		return new Promise(async (resolve) => {
			runInAction(async () => {
				await this.set(KEYS.IMPERSONATED, null);
				await this.set(KEYS.IMPERSONATED_ACCESS_TOKEN, null);
				await this.set(KEYS.IMPERSONATED_REFRESH_TOKEN, null);
				await this.set(KEYS.ACCOUNT, null);
				await this.set(KEYS.ACCESS_TOKEN, null);
				await this.set(KEYS.REFRESH_TOKEN, null);
				await this.set(KEYS.EXPIRES_IN, null);
				await this.set(KEYS.EXPIRES_AT, null);
				await this.set(KEYS.LAST_ACTIVITY, null);
				await this.set(KEYS.PROFILE, null);
				AcClearState();
				resolve();
			});
		});
	};

	@action
	clearImpersonation = () => {
		return new Promise(async (resolve) => {
			runInAction(async () => {
				await this.set(KEYS.IMPERSONATED, null);
				await this.set(KEYS.IMPERSONATED_ACCESS_TOKEN, null);
				await this.set(KEYS.IMPERSONATED_REFRESH_TOKEN, null);

				AcRemoveState(KEYS.IMPERSONATED);
				AcRemoveState(KEYS.IMPERSONATED_ACCESS_TOKEN);
				AcRemoveState(KEYS.IMPERSONATED_REFRESH_TOKEN);

				await app.store.resetStores();
				await this.handleInitialPermissionsCheck(this.current_permissions);

				resolve();
			});
		});
	};

	@action
	handleInitialPermissionsCheck = (permissions) => {
		return new Promise((resolve) => {
			runInAction(async () => {
				if (!permissions[PERMISSIONS.NAVIGATION.DASHBOARD_EQUIPMENT]) {
					AcRemoveState(KEYS.EQUIPMENT);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.DASHBOARD_PROJECT_OVERVIEW]) {
					AcRemoveState(KEYS.PROJECTS);
					AcRemoveState(KEYS.PROJECT);
					AcRemoveState(KEYS.PROJECT_OPTIONS);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.DASHBOARD_PROJECT_DETAIL]) {
					AcRemoveState(KEYS.PROJECT);
					AcRemoveState(KEYS.PROJECT_OPTIONS);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.COMPANY]) {
					AcRemoveState(KEYS.COMPANIES);
					AcRemoveState(KEYS.COMPANY);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.CONFIGURATION]) {
					AcRemoveState(KEYS.CONFIGURATIONS);
					AcRemoveState(KEYS.CONFIGURATION);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.CONTRACT]) {
					AcRemoveState(KEYS.CONTRACTS);
					AcRemoveState(KEYS.CONTRACT);
				}

				if (
					!permissions[PERMISSIONS.NAVIGATION.EQUIPMENT_CONTROL_UNITS] ||
					!permissions[PERMISSIONS.NAVIGATION.DASHBOARD_CONTROL_UNIT]
				) {
					AcRemoveState(KEYS.CONTROL_UNITS);
					AcRemoveState(KEYS.CONTROL_UNIT);
					AcRemoveState(KEYS.LIVEVIEW);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.EQUIPMENT_TYPE_CONTROL_UNITS]) {
					AcRemoveState(KEYS.CONTROL_UNIT_TYPES);
					AcRemoveState(KEYS.CONTROL_UNIT_TYPE);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.EQUIPMENT_HAMMERS]) {
					AcRemoveState(KEYS.HAMMERS);
					AcRemoveState(KEYS.HAMMER);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.EQUIPMENT_TYPE_HAMMERS]) {
					AcRemoveState(KEYS.HAMMER_TYPES);
					AcRemoveState(KEYS.HAMMER_TYPE);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.EQUIPMENT_POWERPACKS]) {
					AcRemoveState(KEYS.POWERPACKS);
					AcRemoveState(KEYS.POWERPACK);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.EQUIPMENT_TYPE_POWERPACKS]) {
					AcRemoveState(KEYS.POWERPACK_TYPES);
					AcRemoveState(KEYS.POWERPACK_TYPE);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.PROJECT]) {
					AcRemoveState(KEYS.PROJECTS);
					AcRemoveState(KEYS.PROJECT);
					AcRemoveState(KEYS.PROJECT_OPTIONS);
				}

				if (!permissions[PERMISSIONS.NAVIGATION.USER]) {
					AcRemoveState(KEYS.USERS);
				}

				resolve();
			});
		});
	};

	@action
	handleAuthentication = (result) => {
		return new Promise(async (resolve) => {
			runInAction(async () => {
				if (result) {
					const { access_token, refresh_token, expires_in, permissions } =
						result;
					let account = result;

					if (access_token) await this.set(KEYS.ACCESS_TOKEN, access_token);
					if (refresh_token) await this.set(KEYS.REFRESH_TOKEN, refresh_token);

					// const expires_at = dayjs().add(expires_in, 'seconds').format('X');
					const expires_at = dayjs().add(expires_in, 'seconds');

					if (permissions) {
						const len = permissions.length;
						let n = 0;
						let formatted = {};

						for (n; n < len; n++) {
							const item = permissions[n];
							formatted[item.toUpperCase()] = true;
						}

						account.permissions = formatted;
					}

					this.handleInitialPermissionsCheck(account.permissions);

					await this.set(KEYS.EXPIRES_IN, expires_in);
					await this.set(KEYS.EXPIRES_AT, expires_at);
					if (account) await this.set(KEYS.ACCOUNT, { ...account, expires_at });
				}
				resolve();
			});
		});
	};

	@action
	handleImpersonation = (result) => {
		return new Promise(async (resolve) => {
			runInAction(async () => {
				if (result) {
					const { access_token, refresh_token, expires_in, permissions } =
						result;
					let account = result;

					if (access_token)
						await this.set(KEYS.IMPERSONATED_ACCESS_TOKEN, access_token);
					if (refresh_token)
						await this.set(KEYS.IMPERSONATED_REFRESH_TOKEN, refresh_token);

					const expires_at = dayjs().add(expires_in, 'seconds').format('X');

					if (permissions) {
						const len = permissions.length;
						let n = 0;
						let formatted = {};

						for (n; n < len; n++) {
							const item = permissions[n];
							formatted[item.toUpperCase()] = true;
						}

						account.permissions = formatted;
					}

					await this.handleInitialPermissionsCheck(account.permissions);

					if (account)
						await this.set(KEYS.IMPERSONATED, { ...account, expires_at });
				}

				await app.store.resetStores();

				resolve();
			});
		});
	};

	@action
	login = (credentials) => {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.login(credentials)
			.then(async (response) => {
				await this.handleAuthentication(response);
				await app.store.toasters.clear_queue();

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				this.setLoading(false);

				if (!axios.isCancel(error)) {
					const code = AcFormatErrorCode(error);
					let description = AcFormatErrorMessage(error);

					if (AcIsSet(code)) {
						switch (code) {
							case 400:
								description =
									'The email address or password you entered is incorrect. Please verify your input and try again.';
								break;

							case 401:
								description =
									'The email address or password you entered is incorrect. Please verify your input and try again.';
								break;

							case 403:
								description = `Well, this is unexpected. You don't have access to the requested resource(s).`;
								break;

							default:
						}
					}

					app.store.toasters.add({
						variant: 'error',
						title: 'Failed to log in',
						description,
						code,
					});

					throw error;
				}
			});
	};

	@action
	forgot_password = (credentials) => {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.forgot_password(credentials)
			.then((response) => {
				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				if (!axios.isCancel(error))
					app.store.toasters.add({
						variant: 'error',
						title: 'Failed to request a recovery link',
						description: AcFormatErrorMessage(error),
					});

				this.setLoading(false);

				if (!axios.isCancel(error)) throw error;
			});
	};

	@action
	reset_password = (credentials) => {
		if (!credentials) return;

		this.setLoading(true);

		return app.store.api.auth
			.reset_password(credentials)
			.then((response) => {
				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				if (!axios.isCancel(error))
					app.store.toasters.add({
						variant: 'error',
						title: 'Failed to save your new password',
						description: AcFormatErrorMessage(error),
					});

				this.setLoading(false);

				if (!axios.isCancel(error)) throw error;
			});
	};

	@action
	impersonate = async ({ id, name }) => {
		if (!id) return;

		this.setLoading(true);

		const toast = await app.store.toasters.add({
			variant: 'pending',
			description: 'Requesting impersonating procedure',
			indeterminate: true,
		});

		return app.store.api.auth
			.impersonate(id)
			.then(async (response) => {
				await this.handleImpersonation(response);

				app.store.toasters.update(toast.id, {
					variant: 'success',
					description: `You are now impersonating user <strong>${name}</strong>`,
					variant: 'success',
					indeterminate: false,
				});

				this.setLoading(false);

				return response;
			})
			.catch((error) => {
				if (!axios.isCancel(error))
					app.store.toasters.update(toast.id, {
						title: `Failed to start impersonating user <strong>${name}</strong>`,
						description: AcFormatErrorMessage(error),
						code: AcFormatErrorCode(error),
						variant: 'error',
						indeterminate: false,
					});

				this.setLoading(false);

				if (!axios.isCancel(error)) throw error;
			});
	};

	@action
	stop_impersonating = (id = this.current_account_id) => {
		if (!id) return;

		this.setLoading(true);

		return app.store.api.auth
			.stop_impersonating(id)
			.then(async (response) => {
				await this.clearImpersonation();

				app.store.toasters.add({
					variant: 'success',
					description: 'You have successfully stopped impersonating.',
				});

				this.setLoading(false);

				return response;
			})
			.catch(async (error) => {
				await this.clearImpersonation();

				app.store.toasters.add({
					variant: 'success',
					description: 'You have successfully stopped impersonating.',
				});

				this.setLoading(false);

				return error;
			});
	};

	@action
	logout = () => {
		if (_authTimer) clearTimeout(_authTimer);

		return new Promise(async (resolve) => {
			await this.set(KEYS.LOADED, false);
			await this.clearAuthentication();

			// app.store.toasters.clear_queue();
			app.store.toasters.add({
				variant: 'success',
				description: 'You have successfully been logged out.',
			});

			resolve();
		});
	};

	@action
	unAuthenticate = () => {
		if (_authTimer) clearTimeout(_authTimer);

		const cancelRequestsEvent = new CustomEvent('cancelRequests');
		window.dispatchEvent(cancelRequestsEvent);

		return new Promise(async (resolve) => {
			await this.clearAuthentication();
			await this.set(KEYS.LOADED, false);

			// app.store.toasters.clear_queue();
			app.store.toasters.add({
				variant: 'error',
				description: 'The current session has ended. Log in again to continue.',
			});

			resolve();
		});
	};

	@action
	set = (target, value) => {
		if (AcIsUndefined(target)) return;
		if (AcIsUndefined(this[target])) return;
		if (AcIsUndefined(value)) return;

		return new Promise((resolve) => {
			this[target] = value;
			AcSaveState(target, value);
			resolve();
		});
	};
}

export default AuthStore;
