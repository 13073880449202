// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcIsUndefined,
  AcFormatRequestParameters,
  AcFormatRawDataAsList,
} from '@utils';

const _default = {
  options: {},
  companies: null,
  company: null,
};

let app = {};

export class CompaniesStore {
  constructor(store) {
    makeObservable(this);

    // AcAutoLoad(this, KEYS.COMPANIES);
    // AcAutoLoad(this, KEYS.COMPANY);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  companies = null;

  @observable
  company = null;

  @computed
  get current_companies() {
    return this.companies;
  }

  @computed
  get current_companies_list() {
    if (!this.current_companies || !this.current_companies.data) return [];

    const result = new AcFormatRawDataAsList(this.current_companies.data, {
      ID: 'id',
      NAME: 'name',
      COUNTRY_CODE: 'country_code',
      WEBSITE: 'website',
    });

    return result;
  }

  @computed
  get current_company() {
    return this.company;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    if (this.current_query !== input) this.query = input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (options, load = true) => {
    if (load) this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.companies
      .list(params)
      .then((response) => {
        this.set(KEYS.COMPANIES, response, true);

        if (response.meta && response.meta.current_page)
          this.setPageNumber(response.meta.current_page);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve companies',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_by_id = (id) => {
    this.setLoading(true);

    return app.store.api.companies
      .get_by_id(id)
      .then((response) => {
        this.set(KEYS.COMPANY, response, true);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve company with id: '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  store = (data) => {
    this.setBusy(true);

    return app.store.api.companies
      .store(data)
      .then(async (response) => {
        await this.set(KEYS.COMPANY, response, true);

        app.store.toasters.add({
          variant: 'success',
          title: `Company <strong>${response.name}</strong> has been added.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to store company`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update = (id, data) => {
    this.setBusy(true);

    return app.store.api.companies
      .update(id, data)
      .then(async (response) => {
        await this.set(KEYS.COMPANY, response, true);

        app.store.toasters.add({
          variant: 'success',
          description: `Company details saved successfully.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update company (id: ${id})`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  sync = (id) => {
    this.setBusy(true);
    return app.store.api.erp
      .syncCompany(id)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          description: `Sync started successfully`,
        });

        this.setBusy(false);
        return response;
      })
      .finally(() => window.location.reload())
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to sync company (id: ${id})`,
            description: AcFormatErrorMessage(error),
          });
        this.setBusy(false);
        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete = ({ id, name }) => {
    this.setBusy(true);

    return app.store.api.companies
      .delete(id)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          title: `Company <strong>${name}</strong> deleted`,
        });

        await this.list();

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete company <strong>${name}</strong>`,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save = false) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save = false) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = false) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default CompaniesStore;
