// Imports => Vendor
import axios from 'axios';

// Imports => MOBX
import { makeObservable, observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import {
  AcSanitize,
  AcAutoLoad,
  AcAutoSave,
  AcSaveState,
  AcRemoveState,
  AcIsSet,
  AcIsNull,
  AcFormatErrorMessage,
  AcFormatRawDataAsList,
  AcIsUndefined,
  AcFormatRequestParameters,
} from '@utils';

const _default = {
  options: {},
  notifications: null,
  contracts: null,
  contract: null,
};

let app = {};

export class ContractsStore {
  constructor(store) {
    makeObservable(this);

    // AcAutoLoad(this, KEYS.CONTRACTS);
    // AcAutoLoad(this, KEYS.CONTRACT);
    AcAutoSave(this);

    app.store = store;
  }

  @observable
  notifications = null;

  @observable
  contracts = null;

  @observable
  contract = null;

  @observable
  contract_configurations = null;

  @computed
  get current_notifications() {
    return this.notifications || [];
  }

  @observable
  orderList = [];

  @computed
  get current_notifications_count() {
    return (this.notifications && this.notifications.length) || 0;
  }

  @computed
  get current_contracts() {
    return this.contracts;
  }

  @computed
  get current_contract() {
    return this.contract;
  }

  @computed
  get current_configurations() {
    return this.contract_configurations;
  }

  @computed
  get current_configurations_list() {
    if (!this.current_configurations || !this.current_configurations.data)
      return [];

    let result = new AcFormatRawDataAsList(this.current_configurations.data, {
      ID: 'id',
      NAME: 'name',
      CONTROL_UNIT: 'control_unit',
      HAMMER: 'hammer',
      POWERPACK: 'powerpack',
    });

    return result;
  }

  @observable
  loading = {
    state: false,
    message: null,
  };

  @computed
  get is_loading() {
    return this.loading.state;
  }

  @action
  setLoading = (state = false, message = null) => {
    this.loading = {
      state,
      message,
    };
  };

  @observable
  busy = {
    state: false,
    message: null,
  };

  @computed
  get is_busy() {
    return this.busy.state;
  }

  @action
  setBusy = (state = false, message = null) => {
    this.busy = {
      state,
      message,
    };
  };

  @observable
  order_by = {
    field: null,
    direction: KEYS.ASCENDING,
  };

  @observable
  page = 1;

  @observable
  query = null;

  @action
  setQuery = (input) => {
    const _input = AcSanitize(input);
    if (this.query !== _input) this.query = _input;
  };

  @action
  resetParams = () => {
    this.page = 1;
    this.query = null;
    this.per_page = 0;
    this.order_by = { field: null, direction: KEYS.ASCENDING };
  };

  @computed
  get current_order_by() {
    return this.order_by;
  }

  @action
  setPageNumber = (num) => {
    if (this.page !== num) this.page = num;
  };

  @action
  setPerPage = (num) => {
    if (this.per_page !== num) this.per_page = num;
  };

  @action
  setOrderBy = (field) => {
    let order_by = this.order_by;

    if (order_by.field === field) {
      order_by.direction =
        order_by.direction === KEYS.ASCENDING
          ? KEYS.DESCENDING
          : KEYS.ASCENDING;
    } else order_by.direction = KEYS.ASCENDING;

    order_by.field = field;

    this.order_by = order_by;
    this.setPageNumber(1);
  };

  @action
  list = (options) => {
    this.setLoading(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.contracts
      .list(params)
      .then((response) => {
        this.set(KEYS.CONTRACTS, response, true);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve contracts',
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  list_configurations = (id, options) => {
    this.setBusy(true);

    const params = AcFormatRequestParameters(this, options);

    return app.store.api.contracts
      .list_configurations(id, params)
      .then((response) => {
        this.set(KEYS.CONTRACT_CONFIGURATIONS, response, true);

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: 'Failed to retrieve configurations',
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  get_by_id = (id) => {
    this.setLoading(true);

    return app.store.api.contracts
      .get_by_id(id)
      .then((response) => {
        this.set(KEYS.CONTRACT, response, true);

        this.setLoading(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve contract with id '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
          });

        this.setLoading(false);

        if (!axios.isCancel(error)) throw error;
      });
  };
  @action
  getOrderList = (id) => {
    this.setBusy(true);
    return app.store.api.contracts
      .getOrderList(id)
      .then((response) => {
        this.set(KEYS.ORDER_LIST, response, true);
        this.setBusy(false);
        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve contract details with id '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
          });
        this.setBusy(false);
        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  store = (data) => {
    this.setBusy(true);

    return app.store.api.contracts
      .store(data)
      .then(async (response) => {
        await this.set(KEYS.CONTRACT, response, true);

        app.store.toasters.add({
          variant: 'success',
          title: `Contract <strong>${response.name}</strong> has been added.`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to store contract`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  updateEquipmentExpDate = (id, data) => {
    this.setBusy(true);

    return app.store.api.contracts
      .updateExpirationDate(id, data)
      .then(async (response) => {
        await this.set(KEYS.CONTRACT, response, true);
        app.store.toasters.add({
          variant: 'success',
          description: 'Expiration date saved successfully.',
        });
        this.setBusy(false);
        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update expiration date (id: ${id})`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);
        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  update = (id, data) => {
    this.setBusy(true);

    return app.store.api.contracts
      .update(id, data)
      .then(async (response) => {
        await this.set(KEYS.CONTRACT, response, true);

        app.store.toasters.add({
          variant: 'success',
          description: 'Contract details saved successfully.',
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to update contract (id: ${id})`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  delete = ({ id, name }) => {
    this.setBusy(true);

    return app.store.api.contracts
      .delete(id)
      .then(async (response) => {
        app.store.toasters.add({
          variant: 'success',
          title: `Contract <strong>${name}</strong> deleted`,
        });

        await this.list();

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to delete contract <strong>${name}</strong>`,
          });

        this.setBusy(false);

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  add_equipment = (id, data) => {
    this.setBusy(true);

    return app.store.api.contracts
      .add_equipment(id, data)
      .then(async (response) => {
        await this.set(KEYS.CONTRACT, response, true);

        app.store.toasters.add({
          variant: 'success',
          description: `Selected equipment has been added`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to add the selected equipment`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);
      });
  };

  @action
  remove_equipment = (id, data) => {
    this.setBusy(true);

    return app.store.api.contracts
      .remove_equipment(id, { equipment: [data.id] })
      .then(async (response) => {
        await this.set(KEYS.CONTRACT, response, true);

        app.store.toasters.add({
          variant: 'success',
          description: `Equipment <strong>${data.object_no}</strong> has been removed from this contract`,
        });

        this.setBusy(false);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to remove <strong>${data.object_no}</strong> from this contract`,
            description: AcFormatErrorMessage(error),
          });

        this.setBusy(false);
      });
  };

  @action
  list_notifications = () => {
    return app.store.api.contracts
      .notifications()
      .then((response) => {
        this.set(KEYS.NOTIFICATIONS, response, true);

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to retrieve notifications`,
            description: AcFormatErrorMessage(error),
          });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  dismiss = (id) => {
    return app.store.api.contracts
      .dismiss(id)
      .then(async (response) => {
        await this.list_notifications();

        return response;
      })
      .catch((error) => {
        if (!axios.isCancel(error))
          app.store.toasters.add({
            variant: 'error',
            title: `Failed to dismiss notification with id '<strong>${id}</strong>'`,
            description: AcFormatErrorMessage(error),
          });

        if (!axios.isCancel(error)) throw error;
      });
  };

  @action
  set = (target, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (AcIsUndefined(value)) return;

    this[target] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  setState = (target, property, value, save) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;
    if (!AcIsSet(property)) return;
    if (AcIsUndefined(value)) return;

    this[target][property] = value;
    if (save) AcSaveState(target, value);
  };

  @action
  reset = (target, save = true) => {
    if (!AcIsSet(target)) return;
    if (AcIsUndefined(this[target])) return;

    return new Promise((resolve) => {
      this[target] = _default[target];
      if (save && AcIsNull(_default[target])) {
        AcRemoveState(target);
      } else if (save) {
        AcSaveState(target, _default[target]);
      }

      resolve();
    });
  };
}

export default ContractsStore;
